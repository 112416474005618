import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppConfig from '../appconfig';

const EmailConfirmation = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const navigate = useNavigate();

    const resendEmail = async () => {
        await fetch(`${AppConfig.ApiBaseUrl}api/resendConfirmationEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
    }

    return (
        <div className="text-left min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 rounded shadow-md">
                <p className="text-2xl font-bold mb-6 text-center">Ověření emailu</p>
                <p>Vítej mezi dobrodruhy! Na email jsme ti poslali odkaz pro dokončení registrace.</p>
                
                <button 
                    onClick={() => navigate('/login')}
                    className=" mt-4 w-full flex justify-center px-4 py-2 bg-black hover:bg-gray-800 text-white rounded transition"
                    >
                    Přihlásit se
                </button>

                <p className="mt-6 text-sm text-gray-500">Nepřišel ti email?
                    <button onClick={resendEmail} className="text-blue-500 hover:underline ml-1">Znovu poslat email</button>
                </p>
            </div>
        </div>

    );
};

export default EmailConfirmation;
