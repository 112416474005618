import React, { useState, useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Bivak } from '../entities/Bivak';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import AppConfig from '../appconfig';
import ErrorModal from '../components/modals/ErrorModalComponent';
import ErrorMessages from '../ErrorMessages';
import PulseLoader from "react-spinners/PulseLoader";

const AddBivak = () => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<L.Map | null>(null);
    const markerRef = useRef<L.Marker>();
    const [images, setImages] = useState<File[]>([]);
    const [formData, setFormData] = useState<Bivak>({
        name: '',
        position: { latitude: 0, longitude: 0 },
        capacity: undefined,
        description: ''
    });
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const myCustomIcon = L.icon({
        iconUrl: '/c-marker-icon.png',
        iconSize: [25, 25]
    });

    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = L.map(mapContainerRef.current).setView([49.5, 17], 8);
            L.tileLayer(`${AppConfig.ApiBaseUrl}api/MapTiles/{z}/{x}/{y}`, {
                maxZoom: 19,
                attribution: '© OpenStreetMap contributors'
            }).addTo(mapRef.current);

            const LogoControl = L.Control.extend({
                options: {
                    position: 'bottomleft',
                },
                onAdd: function () {
                    const container = L.DomUtil.create('div');
                    const link = L.DomUtil.create('a', '', container);
                    link.setAttribute('href', 'http://mapy.cz/');
                    link.setAttribute('target', '_blank');
                    link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
                    L.DomEvent.disableClickPropagation(link);
                    return container;
                },
            });
            new LogoControl().addTo(mapRef.current);

            mapRef.current.on('click', function (e) {
                const { lat, lng } = e.latlng;

                setFormData(currentFormData => ({
                    ...currentFormData,
                    position: {
                        latitude: lat,
                        longitude: lng
                    }
                }));

                if (markerRef.current) {
                    markerRef.current.setLatLng(e.latlng);
                } else {
                    markerRef.current = L.marker(e.latlng, { icon: myCustomIcon }).addTo(mapRef.current as L.Map);
                }
            });
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.off('click');
                if (markerRef.current) {
                    markerRef.current.remove();
                }
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    // eslint-disable-next-line
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setImages(Array.from(event.target.files));
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files) {
            setImages(Array.from(event.dataTransfer.files));
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if (!formData.name || !formData.position.latitude || !formData.position.longitude) {
            setErrorMessage("Název a pozice jsou povinné.");
            setIsErrorModalOpen(true);
            return;
        }
        
        const newFormData = new FormData();
        newFormData.append('name', formData.name);
        newFormData.append('latitude', String(formData.position.latitude));
        newFormData.append('longitude', String(formData.position.longitude));
        newFormData.append('capacity', String(formData.capacity));
        newFormData.append('description', String(formData.description));
        
        images.forEach((file, index) => {
            newFormData.append(`images[${index}]`, file, file.name);
        });
        
        try {
            setIsLoading(true); 
            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/bivaks`, {

                method: 'POST',
                body: newFormData,
            });

            if (response.status === 401) {
                setErrorMessage(ErrorMessages.NotSignedIn);
                setIsErrorModalOpen(true);
            }

            await response.json();
            navigate('/list');
        } catch (error) {
            console.error('There was an error!', error);
        }
        finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <div ref={mapContainerRef} className="w-full md:w-4/5 lg:w-3/4 xl:w-2/3 h-[500px] bg-white shadow-lg mb-4 z-0"></div>

            <form onSubmit={handleSubmit} className="w-full md:w-4/5 lg:w-3/4 xl:w-2/3 flex flex-col md:flex-row md:justify-between md:items-start gap-4">


                <div className="flex-1">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Název"
                        required
                        className="mb-2 p-2 border border-gray-300 rounded w-full"
                    />

                    <input
                        type="number"
                        name="capacity"
                        value={formData.capacity || ''}
                        onChange={handleChange}
                        placeholder="Kapacita"
                        className="mb-2 p-2 border border-gray-300 rounded w-full"
                    />

                    <input
                        type="text"
                        placeholder="Longitude, Latitude"
                        value={`${formData.position.longitude}, ${formData.position.latitude}`}
                        readOnly
                        className="p-2 border border-gray-300 rounded w-full bg-gray-200 text-gray-500"
                    />

                    <label
                        htmlFor="file-upload"
                        className="mt-6 p-2 w-full border rounded cursor-pointer bg-black text-white flex justify-center items-center"
                    >
                        <i className="fa-solid fa-plus mr-2"></i> Přidat fotky
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleImageChange}
                        className="hidden"
                    />

                    <div
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        className="border-dashed border-2 border-gray-300 p-6 rounded mt-4 text-center"
                    >
                        Přetáhněte fotky sem nebo klikněte pro přidání.
                    </div>
                    <div className="mt-4 grid grid-cols-3 gap-2">
                        {images.map((file, index) => (
                            <img
                                key={index}
                                src={URL.createObjectURL(file)}
                                alt={`Preview ${index}`}
                                className="w-full h-auto rounded"
                            />
                        ))}
                    </div>
                </div>

                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Popis"
                    className="flex-1 md:flex-none md:w-1/2 h-32 p-2 border border-gray-300 rounded resize-none"
                ></textarea>

                <button 
                    type="submit" 
                    className={`w-full md:w-auto px-4 py-2 text-white rounded transition ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-black hover:bg-gray-800'}`}
                    disabled={isLoading}
                >
                    Přidat
                </button>
            </form>
            <div>
                <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
            </div>
            <PulseLoader
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                color="#000"
                loading={isLoading}
                size={80}
            />
        </div>

    );

}

export default AddBivak;
