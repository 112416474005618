import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import AuthService from '../services/AuthService';
import { User } from '../entities/User';
import AppConfig from '../appconfig';
import { Bivak } from '../entities/Bivak';
import AccommodationItem from '../components/AccommodationItemComponent';
import { Reservation } from '../entities/Reservation';
import ReservationItemComponent from '../components/ReservationItemComponent';
import ErrorModal from '../components/modals/ErrorModalComponent';
import ErrorMessages from '../ErrorMessages';

const Profile = () => {
  const [user, setUser] = useState<User>();
  const [newUserName, setNewUserName] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [likedBivaks, setLikedBivaks] = useState<Bivak[]>();
  const [reservations, setReservations] = useState<Reservation[]>();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchLikedBivaks = async () => {
    const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/users/bivaks`);

    if (response.ok) {
      const data: Bivak[] = await response.json();
      setLikedBivaks(data);
    } else {
      console.error('Failed to fetch liked bivaks');
    }
  }

  const fetchUserReservations = async (userName: string) => {
    const response = await fetch(`${AppConfig.ApiBaseUrl}api/reservations?userName=${userName}`);

    if (response.ok) {
      const data: Reservation[] = await response.json();
      setReservations(data);
    } else {
      console.error('Failed to fetch reservations');
    }
  }

  useEffect(() => {
    // Fetch current user name
    const fetchUserName = async () => {
      try {
        const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/users`);
        if (response.ok) {
          const data: User = await response.json();
          setUser(data);
          fetchUserReservations(data.userName);
        } else if (response.status === 401) {
          setErrorMessage(ErrorMessages.NotSignedIn);
          setIsErrorModalOpen(true);
        } else {
          console.error('Failed to fetch user name');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUserName();
    fetchLikedBivaks();
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setNewUserName(event.target.value);
    setIsFormValid(input.length >= 3);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/users/${newUserName}`, {
        method: 'PATCH'
      });

      if (response.ok) {
        if (user) {
            setUser({ ...user, customName: newUserName });
          }
      } else if (response.status === 401) {
        setErrorMessage(ErrorMessages.NotSignedIn);
        setIsErrorModalOpen(true);
      } else {
        console.error('Failed to update user name');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='max-w-screen-xl mx-auto px-5'>
      <p className='text-3xl font-bold mb-4'>{user?.customName ?? user?.userName}</p>
      {user?.customName == null && (
        <div className='flex flex-col md:flex-row items-start md:items-center text-left'>
          <form onSubmit={handleSubmit} className='w-full md:w-auto md:mr-4'>
            <label className='block mb-2 text-xl font-semibold'>
              Změna jména:
              <input
                type='text'
                value={newUserName}
                onChange={handleInputChange}
                placeholder={user?.customName ?? user?.userName}
                className='p-2 border border-gray-300 rounded w-full'
              />
            </label>
            <button 
              type='submit' 
              className='w-full md:w-auto px-4 py-2 bg-black hover:bg-gray-800 text-white rounded transition'
              disabled={!isFormValid}
            >
              Změnit
            </button>
          </form>
        </div>
      )}
      <div>
        <h3 className='text-xl text-left mb-2 mt-5 font-semibold'>Nadcházející rezervace:</h3>
        {reservations === undefined ? <p>Zatím nemáš žádné rezervované místo na spaní</p> : reservations.map(reservation => (
          <ReservationItemComponent key={reservation.id} reservation={reservation} />
        ))}
      </div>
      <div className='pb-5'>
        <h3 className='text-xl text-left mb-2 mt-5 font-semibold'>Oblíbená místa na spaní:</h3>
        {likedBivaks === undefined ? <p>Zatím nemáš žádné oblíbené místo na spaní</p> : likedBivaks.map(bivak => (
          <AccommodationItem key={bivak.id} bivak={bivak} />
        ))}
      </div>
      <div>
          <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
      </div>
    </div>
  );
};

export default Profile;
