import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/cs';
import { Reservation } from '../entities/Reservation';
import { useNavigate } from 'react-router-dom';


const CalendarComponent = ({ events }: {events: Reservation[]}) => {
  moment.locale('cs');
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();

  const handleSelectEvent = (event: Reservation) => {
    navigate(`/reservation/${event.id}`);
  };

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        style={{ height: 500 }}
        views={['month']} // Only enable the month view
        defaultView={Views.MONTH} // Set the default view to month
        messages={{
          month: 'Měsíc',
          today: 'Dnes',
          previous: 'Předchozí',
          next: 'Další',
        }}
      />
    </div>
  );
};

export default CalendarComponent;
