import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(''); // Reset error messages
        try {
            await AuthService.register(email, password);
            navigate(`/email-confirmation?email=${email}`);
        } catch (error: any) {
            setError(error.message);
        }
    };

    return (
        <div className="text-left min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
            <div className="max-w-md w-full space-y-8 bg-white p-8 rounded shadow-md">
                <p className="text-2xl font-bold mb-6 text-center">Registrace</p>
                <p className="mb-6">Vítej mezi dobrodruhy! Zaregistruj se a objev s námi ta nejlepší místa na přespání v přírodě. Jako registrovaný uživatel můžeš:</p>
                <ul className="list-disc list-inside mb-6 space-y-2">
                    <li className="flex items-start">
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500 mt-1" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Přidávat nová místa na spaní</span>
                    </li>
                    <li className="flex items-start">
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500 mt-1" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Komentovat a hodnotit místa</span>
                    </li>
                    <li className="flex items-start">
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500 mt-1" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Ukládat si svá oblíbená místa</span>
                    </li>
                    <li className="flex items-start">
                        <svg className="flex-shrink-0 h-5 w-5 text-green-500 mt-1" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L9 11.586l6.293-6.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">Sdílet své fotky a zážitky s ostatními</span>
                    </li>
                </ul>
                <form className="mt-8 space-y-6" onSubmit={handleRegister}>
                    <div className="rounded -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>
                            <input 
                                id="email" 
                                name="email" 
                                type="text" 
                                required 
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                placeholder="Email" 
                                value={email} 
                                onChange={e => setEmail(e.target.value)} 
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input 
                                id="password" 
                                name="password" 
                                type="password" 
                                required 
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                placeholder="Heslo" 
                                value={password} 
                                onChange={e => setPassword(e.target.value)} 
                            />
                        </div>
                    </div>
                    {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
                    <button 
                        type="submit" 
                        className="group relative w-full flex justify-center px-4 py-2 bg-black hover:bg-gray-800 text-white rounded transition"
                    >
                        Registrovat
                    </button>
                </form>
            </div>
        </div>

    );
};

export default Register;
