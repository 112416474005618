import React, { useState, useEffect } from 'react';
import DatePickerComponent from './DatePickerComponent';
import CalendarComponent from './CalendarComponent';
import { Reservation } from '../entities/Reservation';
import AppConfig from '../appconfig';
import { Bivak } from '../entities/Bivak';
import AuthService from '../services/AuthService';
import ErrorModal from './modals/ErrorModalComponent';
import ErrorMessages from '../ErrorMessages';
import { useNavigate } from 'react-router-dom';

const ReservationComponent = ({bivak }: {bivak: Bivak}) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await fetch(`${AppConfig.ApiBaseUrl}api/reservations?bivakid=${bivak.id}`);
      const data: Reservation[] = await response.json();
      setReservations(data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  const handleDateSelect = async (date: Date, info: string) => {
    const newReservation = {
      start: date,
      end: new Date(date.getTime() + 60 * 60 * 1000), // Add one hour
      title: 'Rezervace',
      bivakId: bivak.id,
      info: info
    };

    try {
      const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/reservations`, {
        method: 'POST',
        body: JSON.stringify(newReservation),
      });

      if (response.ok) {
        const addedReservation: Reservation = await response.json();
        setReservations([...reservations, addedReservation]);
        navigate('/profile');
      } else {
        if (response.status === 401)
        {
          setErrorMessage(AuthService.isLogged() ? "Nastala chyba." : ErrorMessages.NotSignedIn);
          setIsErrorModalOpen(true);
        }
        else {
          const message: string = await response.json();
          setErrorMessage(message);
          setIsErrorModalOpen(true);
        }
      }
    } catch (error) {
      console.error('Error adding reservation:', error);
    }
  };

  return (
    <div className='mt-10 mb-10'>
      <p className='text-2xl font-bold'>Kalendář plánovaných přespání</p>
      <p className='mb-3 text-gray-600'>Nejedná se o opravdové rezervace, ale pouze můžete dát vědět ostatním, že zde v daný den plánujete přespat.</p>
      <CalendarComponent events={reservations} />
      <p className='mt-3 text-gray-600'>Lze vytvořit 1 rezervaci na měsíc k danému přístřešku.</p>
      <DatePickerComponent onDateSelect={handleDateSelect} />
      <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
    </div>
  );
};

export default ReservationComponent;
