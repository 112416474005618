import { useEffect, useState } from "react";
import AuthService from "../../services/AuthService";
import AppConfig from "../../appconfig";
import ErrorMessages from "../../ErrorMessages";
import ErrorModal from "../modals/ErrorModalComponent";

function FavoriteButton({ bivakId }: {bivakId: number}) {
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
    
    const getIsLikedBivak = async () => {
        const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/users/bivaks/${bivakId}`, {
            method: "GET"
        });

        const result: boolean = await response.json();

        setIsLiked(result);

        return result;
    }
    
    useEffect(() => {
        if (localStorage.getItem('token') != null)
            getIsLikedBivak();
    // eslint-disable-next-line        
    }, []);

    const handleAddRemove = async () => {
        try {
            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/users/bivaks/${bivakId}`, {
                method: "POST"
            });
    
            if (response.status >= 200 && response.status < 300) {
                setIsLiked(!isLiked);
            }
            else
            {
                const problemDetailsResponse = await response.json();
                setErrorMessage(AuthService.isLogged() ? problemDetailsResponse.title : ErrorMessages.NotSignedIn);
                setIsErrorModalOpen(true);
            }
        } catch (error: any) {
            setErrorMessage(AuthService.isLogged() ? error.message : ErrorMessages.NotSignedIn);
            setIsErrorModalOpen(true);
        }
    }

    return (
        <div>
            <button onClick={handleAddRemove} className="bg-white hover:bg-gray-100 text-black font-bold py-2 px-4 rounded flex items-center space-x-2">
                <img className="max-w-6" src={isLiked ? "/red-heart-icon.png" : "/heart-thin-icon.png"} alt="map marker" />
            </button>
            <div>
                <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
            </div>
        </div>
    );
}

export default FavoriteButton;