import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppConfig from '../appconfig';
import { Reservation } from '../entities/Reservation';
import ReservationItemComponent from '../components/ReservationItemComponent';

const ReservationDetail = () => {
  const { id } = useParams();
  const [reservation, setReservation] = useState<Reservation>();

  const fetchReservation = async () => {
    const response = await fetch(`${AppConfig.ApiBaseUrl}api/reservations/${id}`);
    const data = await response.json();
    setReservation(data);
  };

  useEffect(() => {
      fetchReservation();
// eslint-disable-next-line
  }, [id]);

  return (
    <div className="max-w-screen-xl mx-auto px-5">
      {reservation && (
        <ReservationItemComponent
          reservation={reservation}
          hideButton={true}
        />
      )}
    </div>
  );
};

export default ReservationDetail;