import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Bivak } from '../entities/Bivak';
import AppConfig from '../appconfig';

const Map = () => {
  const mapRef = useRef<L.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const myCustomIcon = L.icon({
    iconUrl: '/c-marker-icon.png',
    iconSize: [25, 25]
    });

  useEffect(() => {
    if (!mapContainerRef.current) return;  // Ensure the container ref is present

    const map = mapRef.current = mapRef.current || L.map(mapContainerRef.current).setView([49.5, 17], 8);
    L.tileLayer(`${AppConfig.ApiBaseUrl}api/MapTiles/{z}/{x}/{y}`, {
      maxZoom: 19,
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Define and add a custom LogoControl
    const LogoControl = L.Control.extend({
      options: {
        position: 'bottomleft',
      },
      onAdd: function (map: L.Map): HTMLElement {
        const container = L.DomUtil.create('div');
        const link = L.DomUtil.create('a', '', container);
        link.setAttribute('href', 'http://mapy.cz/');
        link.setAttribute('target', '_blank');
        link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
        L.DomEvent.disableClickPropagation(link);
        return container;
      },
    });
    new LogoControl().addTo(map);

    // Fetch data and add markers
    const fetchData = async () => {
        try {
          const response = await fetch(`${AppConfig.ApiBaseUrl}api/mapinfo-bivaks`);
          if (!response.ok) throw new Error('Network response was not ok');
          const bivaks: Bivak[] = await response.json();
          bivaks.forEach(bivak => {
            if (!bivak.position || bivak.position.latitude == null || bivak.position.longitude == null) {
              console.error('Invalid position data', bivak);
              return;
            }

            // Build image URL using the first image in the array, if available
            const imageUrl = bivak.image
            ? `${AppConfig.ImagesPath}/images/${(bivak.image.fileName)}`
            : '/default-image.png';
    
            const marker = L.marker([bivak.position.latitude, bivak.position.longitude], {icon: myCustomIcon}).addTo(map);
            const popupContent = `
          <div>
            <b><a href="/detail/${bivak.id}" class="navigate-link" data-id="${bivak.id}">${bivak.name}</a></b>
            <br>Kapacita: ${bivak.capacity}
            <img src=${imageUrl} alt=${bivak.name} class="w-48 h-48 object-cover rounded-md" />
          </div>
        `;
          marker.bindPopup(popupContent);

          });
        } catch (error) {
          console.error('Failed to fetch Bivaks:', error);
        }
      };
    
      fetchData();

      return () => {
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
  }, [myCustomIcon]);

  return (
    <div className="flex items-center justify-center w-screen h-5/6">
        <div ref={mapContainerRef} className="h-full w-full">
        </div>
    </div>
);
};

export default Map;
