import { format } from "date-fns";
import AppConfig from "../appconfig";
import { Reservation } from "../entities/Reservation";
import AuthService from "../services/AuthService";

const ReservationItemComponent = ({reservation, hideButton}: {reservation: Reservation, hideButton?: boolean}) => {

    const imageUrl = reservation.bivak?.image
        ? `${AppConfig.ImagesPath}/images/${(reservation.bivak.image.fileName)}`
        : '/default-image.png';

    const formatDate = (date: Date): string => {
        return format(date, 'dd.MM.yyyy');
        };

    const  cancelReservation = async (reservationId: number)  => {
        var response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/reservations/${reservationId}`, {
            method: 'DELETE'
        });
        if (response.ok) {
            window.location.reload();
        }
    };

    return (
        <div className="cursor-pointer border border-gray-200 rounded-md p-6 mb-4 shadow flex justify-between">
            <div className="text-left flex-col flex-1 flex justify-between">
                <div>
                    <h3 className="text-2xl font-bold">{reservation.bivak?.name}</h3>
                    <p className="text-sm text-gray-600">Datum: {formatDate(reservation.start!)}</p>
                    <p className="mt-3 text-sm text-gray-600">{reservation.info}</p>
                </div>
                <div className="flex justify-start">
                    {!hideButton && <button onClick={() => cancelReservation(reservation.id)} className="bg-black text-white px-4 py-2 rounded-md">Zrušit rezervaci</button>}
                </div>
            </div>
            <img src={imageUrl} alt={reservation.bivak?.name} className="w-48 h-48 object-cover ml-4 rounded-md" />
        </div>
    );
}

export default ReservationItemComponent