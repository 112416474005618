import React from 'react'

const FooterComponent = () => {
  return (
    <footer className="bg-white text-gray-800 border-t border-gray-200 py-8">
      <div className="container mx-auto flex flex-row items-center justify-center space-x-4">
        <div className="flex items-center mb-4">
          <img src="/logo192.png" alt="KdeSpat Logo" className="h-16 w-auto mr-2" />
        </div>
        <div className="flex flex-col items-start mb-4">
            <p className="text-sm mb-2">Kontakt: <a href="mailto:info@kdespat.cz" className="hover:text-[#fc336c] transition-colors duration-300">info@kdespat.cz</a></p>
            <p className="text-sm">
                Vytvořil <a href="https://github.com/jakubreznak" target="_blank" rel="noopener noreferrer" className="hover:text-[#fc336c] font-bold transition-colors duration-300">jakubreznak</a>
            </p>
            <a href="https://www.facebook.com/profile.php?id=61566433231514" target="_blank" rel="noopener noreferrer" className="mt-2 space-x-2 hover:text-[#fc336c] transition-colors duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className="inline-block">
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                </svg>
                facebook
            </a>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent